<template>
	<b-modal
		:title="`Motivos de muerte / ${getTitle}`"
		size="xlg"
		v-model="isActive"
		centered
		hide-footer
		scrollable
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<b-tabs>
				<b-tab @click="changeTab(0)" :active="tab === 0">
					<template #title>Por Motivos</template>
				</b-tab>
				<b-tab @click="changeTab(1)" :active="tab === 1">
					<template #title>Por Edad</template>
				</b-tab>
			</b-tabs>
			<template v-if="tab === 0">
				<h5 class="text-uppercase">
					Total de muertos:
					<span class="text-danger font-bold">{{ info.deads_lote }}</span>
				</h5>
				<VueApexCharts type="pie" height="400" :options="chartOpts" :series="chartData" />
			</template>
			<template v-if="tab === 1">
				<template v-if="dayLength > 0">
					<div class="d-flex justify-content-center">
						<h5 class="text-uppercase">
							Mortalidad Diaria (Primeros 3 meses):
							<span class="text-danger font-bold">{{ sumaDay }}</span>
						</h5>
					</div>
					<VueApexCharts type="bar" height="350" :options="barDayChartOptions" :series="barDaySeries" />
				</template>
				<template v-if="monthLength > 0">
					<div class="d-flex justify-content-center">
						<h5 class="text-uppercase">
							Mortalidad Mensual (Meses 4 - 12):
							<span class="text-danger font-bold">{{ sumaMonth }}</span>
						</h5>
					</div>
					<VueApexCharts type="bar" height="350" :options="barMonthChartOptions" :series="barMonthSeries" />
				</template>
			</template>
		</b-overlay>
	</b-modal>
</template>

<script>
import lotesService from "@/services/lotes.service"
import moment from "moment"

import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: {
		show: Boolean,
		info: Object,
		isGeneral: { type: Boolean, required: false, default: false },
		season: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		chartData: [1],
		chartOpts: {
			labels: ["base"],
		},
		tab: 0,
		sumaMonth: 0,
		sumaDay: 0,
		totalDeads: 0,
		monthLength: 0,
		dayLength: 0,
		barDayChartOptions: {},
		barDaySeries: [{ name: "", data: [] }],
		barMonthChartOptions: {},
		barMonthSeries: [{ name: "", data: [] }],
	}),
	computed: {
		getTitle() {
			if (!this.isGeneral) return `Lote ${this.info.code || "---"}`

			const tempYear = moment(this.season.start).year()
			return `Lotes de temporada ${tempYear}`
		},
	},
	methods: {
		changeTab(tab) {
			if (tab === 0) {
				this.tab = 0
				this.getReasons()
			}
			if (tab === 1) {
				this.tab = 1
				this.initBarChart()
			}
		},
		completeMonthData(month_data) {
			const completeData = []
			const months = Array.from({ length: 9 }, (_, i) => i + 3) // Meses del 4 al 12

			months.forEach((month) => {
				const existingMonth = month_data.find((data) => parseInt(data.death_month) === month)
				if (existingMonth) {
					completeData.push(existingMonth)
				} else {
					completeData.push({ death_month: month, qty: "0" })
				}
			})

			return completeData
		},
		async initBarChart() {
			this.isLoading = true
			let params = {}
			if (this.isGeneral) {
				params = {
					start_date: this.season.start,
					end_date: this.season.end,
				}
			} else {
				params = {
					lote_id: this.info.id,
				}
			}
			const { data } = await lotesService.getDeathsPerDayReport(params)
			const day_data = data.day
			const month_data = this.completeMonthData([...data.month])
			console.log(month_data)
			this.sumaMonth = month_data.reduce((acc, curr) => acc + Number(curr.qty), 0)
			this.sumaDay = day_data.reduce((acc, curr) => acc + Number(curr.qty), 0)
			this.monthLength = data.month.length
			this.dayLength = day_data.length
			this.barDayChartOptions = {
				chart: {
					type: "bar",
					height: 350,
					toolbar: { show: false },
				},
				plotOptions: {
					bar: { dataLabels: { position: "top" }, borderRadius: 6, borderRadiusApplication: "end" },
				},
				colors: ["#f941ff"],
				dataLabels: {
					enabled: true,
					formatter: (v) => v,
					offsetY: -25,
					style: { fontSize: "15px", colors: ["#000"] },
				},
				legend: {
					showForSingleSeries: true,
					position: "top",
					horizontalAlign: "right",
					fontSize: "18px",
					fontWeight: 600,
					labels: { colors: "#82868b" },
					markers: { width: 20, height: 16, radius: 4 },
					itemMargin: { horizontal: 10 },
				},
				stroke: { show: true, width: 2, colors: ["transparent"] },
				xaxis: {
					labels: {
						style: { colors: "#9aa0ac", fontSize: "14px" },
						rotate: -90,
						rotateAlways: true,
					},
					title: {
						text: "Día",
						style: {
							color: "#9aa0ac",
							fontSize: "14px",
							fontWeight: "bold",
						},
					},
					categories: day_data.map((m) => `${m.death_day}`),
				},
				yaxis: {
					labels: {
						style: { colors: ["#9aa0ac"] },
					},
				},
				fill: { opacity: 1 },
				tooltip: { y: { formatter: (v) => v } },
			}
			this.barDaySeries = [{ name: "Muertes por día", data: day_data.map((m) => m.qty) }]

			this.barMonthChartOptions = {
				chart: {
					type: "bar",
					height: 350,
					toolbar: { show: false },
				},
				plotOptions: {
					bar: { dataLabels: { position: "top" }, borderRadius: 6, borderRadiusApplication: "end" },
				},
				colors: ["#6636ef"],
				dataLabels: {
					enabled: true,
					formatter: (v) => v,
					offsetY: -25,
					style: { fontSize: "20px", colors: ["#000"] },
				},
				legend: {
					showForSingleSeries: true,
					position: "top",
					horizontalAlign: "right",
					fontSize: "18px",
					fontWeight: 600,
					labels: { colors: "#82868b" },
					markers: { width: 20, height: 16, radius: 4 },
					itemMargin: { horizontal: 10 },
				},
				stroke: { show: true, width: 2, colors: ["transparent"] },
				xaxis: {
					labels: { style: { colors: "#9aa0ac", fontSize: "14px" } },
					categories: month_data.map((m) => `Mes ${Number(m.death_month) + 1}`),
				},
				yaxis: {
					labels: { style: { colors: ["#9aa0ac"] } },
				},
				fill: { opacity: 1 },
				tooltip: { y: { formatter: (v) => v } },
			}
			this.barMonthSeries = [{ name: "Muertes por mes", data: month_data.map((m) => m.qty) }]
			this.isLoading = false
		},
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async getReasons() {
			this.isLoading = true

			if (this.isGeneral) {
				await this.getReasonsGeneral()
				return
			}
			const { data } = await lotesService.getLoteDeadsReason({ id: this.info.id })
			const qtys = []
			const labels = []
			data.sort((a, b) => Number(b.qty) - Number(a.qty))
			data.forEach((data) => {
				qtys.push(Number(data.qty))
				labels.push(String(data.reason).toUpperCase())
			})
			this.chartData = qtys
			this.chartOpts = {
				labels,
				dataLabels: {
					formatter: function (value, { seriesIndex }) {
						return `${Number(value).toFixed(1)}%=${qtys[seriesIndex]}`
					},
					style: { colors: ["#fff"] },
				},
				legend: {
					formatter: function (seriesName, opts) {
						return [seriesName, " : ", opts.w.globals.series[opts.seriesIndex]]
					},
				},
			}
			this.isLoading = false
		},
		async getReasonsGeneral() {
			try {
				const { data } = await lotesService.getLoteDeadsReasonGeneral({
					start_date: this.season.start,
					end_date: this.season.end,
				})
				const qtys = []
				const labels = []
				data.sort((a, b) => Number(b.qty) - Number(a.qty))
				data.forEach((data) => {
					qtys.push(Number(data.qty))
					labels.push(String(data.reason).toUpperCase())
				})
				this.chartData = qtys
				this.chartOpts = {
					labels,
					dataLabels: {
						formatter: function (value, { seriesIndex }) {
							return `${Number(value).toFixed(1)}%=${qtys[seriesIndex]}`
						},
						style: { colors: ["#fff"] },
					},
					legend: {
						formatter: function (seriesName, opts) {
							return [seriesName, " : ", opts.w.globals.series[opts.seriesIndex]]
						},
					},
				}
			} catch (error) {
				console.log("errorr: ", error)
			} finally {
				this.isLoading = false
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getReasons()
			} else {
				this.tab = 0
			}
		},
	},
}
</script>
