<template>
	<div>
		<button @click="toggleRecording" :disabled="isRecording">
			{{ isRecording ? "Grabando..." : "Iniciar Grabación" }}
		</button>
		<button @click="stopRecording" :disabled="!isRecording">Detener Grabación</button>
		<p v-if="transcription">Texto transcrito: {{ transcription }}</p>
		<p v-if="error" class="error">{{ error }}</p>
	</div>
</template>

<script>
import axios from "@/axios"
export default {
	props: {
		lote_id: Number,
	},
	data() {
		return {
			isRecording: false,
			transcription: "",
			error: "",
			mediaRecorder: null,
			audioChunks: [],
		}
	},
	methods: {
		async toggleRecording() {
			try {
				this.error = ""
				this.transcription = ""

				// Solicitar acceso al micrófono
				const stream = await navigator.mediaDevices.getUserMedia({ audio: true })

				this.mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" })

				// Capturar los datos de audio
				this.mediaRecorder.ondataavailable = (event) => {
					this.audioChunks.push(event.data)
				}

				// Comportamiento al detener la grabación
				this.mediaRecorder.onstop = async () => {
					const audioBlob = new Blob(this.audioChunks, { type: "audio/webm" })
					this.audioChunks = []

					// Convertir el audio a un formato adecuado antes de enviarlo
					const convertedAudioBlob = await this.convertAudioToWav(audioBlob)

					// Enviar el audio al backend
					await this.sendAudioToBackend(convertedAudioBlob)
				}

				this.mediaRecorder.start()
				this.isRecording = true
			} catch (err) {
				this.error = "No se pudo acceder al micrófono."
				console.error(err)
			}
		},
		stopRecording() {
			if (this.mediaRecorder) {
				this.mediaRecorder.stop()
				this.isRecording = false
			}
		},
		async sendAudioToBackend(audioBlob) {
			const formData = new FormData()
			formData.append("audio_file", audioBlob, "audio.wav")
			formData.append("lote_id", this.lote_id)

			try {
				const { data } = await axios.post("/api/transcribe", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				this.$emit("handleVoiceRecorderResponse", data)
				this.transcription = data.color // Manejar el texto transcrito
			} catch (err) {
				this.error = "Hubo un problema al procesar la transcripción."
				console.error(err)
			}
		},
		// Función para convertir el audio webm a wav
		async convertAudioToWav(audioBlob) {
			const audioContext = new (window.AudioContext || window.webkitAudioContext)()
			const arrayBuffer = await audioBlob.arrayBuffer()
			const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)

			const wavBlob = this.audioBufferToWav(audioBuffer)
			return wavBlob
		},

		// Convertir AudioBuffer a formato WAV
		audioBufferToWav(audioBuffer) {
			const buffer = audioBuffer.getChannelData(0) // Obtén los datos del canal
			const wavData = this.encodeWav(buffer, audioBuffer.sampleRate)
			return new Blob([new DataView(wavData)], { type: "audio/wav" })
		},

		// Codificar AudioBuffer a WAV
		encodeWav(buffer, sampleRate) {
			const bufferLength = buffer.length
			const wavLength = 44 + bufferLength * 2
			const dataView = new DataView(new ArrayBuffer(wavLength))

			// RIFF Header
			this.writeString(dataView, 0, "RIFF")
			dataView.setUint32(4, wavLength - 8, true)
			this.writeString(dataView, 8, "WAVE")

			// Format Chunk
			this.writeString(dataView, 12, "fmt ")
			dataView.setUint32(16, 16, true)
			dataView.setUint16(20, 1, true)
			dataView.setUint16(22, 1, true) // Mono channel
			dataView.setUint32(24, sampleRate, true)
			dataView.setUint32(28, sampleRate * 2, true)
			dataView.setUint16(32, 2, true) // Block align
			dataView.setUint16(34, 16, true) // Bits per sample

			// Data Chunk
			this.writeString(dataView, 36, "data")
			dataView.setUint32(40, bufferLength * 2, true)

			// Write the audio samples
			let offset = 44
			for (let i = 0; i < bufferLength; i++) {
				dataView.setInt16(offset, buffer[i] * 0x7fff, true)
				offset += 2
			}

			return dataView.buffer
		},

		// Función auxiliar para escribir cadenas
		writeString(view, offset, string) {
			for (let i = 0; i < string.length; i++) {
				view.setUint8(offset + i, string.charCodeAt(i))
			}
		},
	},
}
</script>

<style>
.error {
	color: red;
}
</style>
