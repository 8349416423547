<template>
	<RevisionLotesSlot>
		<template v-slot:title>
			<div class="container-title bg-primary rounded px-2 py-75 mb-1">
				<h3 class="mb-0 text-white">LOTE: {{ lote.code }}</h3>
			</div>
			<h3 class="mb-0 text-white">LOTE: {{ lote.code }}</h3>
		</template>

		<template v-slot:subtitle>
			<h2 class="mb-0 text-white mr-1">ETAPA 6</h2>
			<p class="mb-0 font-weight-bolder">{{ lote.title }}</p>
		</template>

		<template v-slot:options>
			<div class="d-flex justify-content-end align-items-center">
				<v-select
					label="description"
					class="mx-1"
					:options="colorOptions"
					:reduce="(opt) => opt.description"
					style="width: 220px"
					v-model="selectedColor1"
					placeholder="Seleccionar color #1"
				>
					<template #option="option">
						<div class="flex items-center">
							<span class="fa-squaress mr-1">
								<feather-icon
									:style="`color: ${option.color}; background: ${option.color}; border-radius: 3px;`"
									icon="SquareIcon"
									size="25"
								/>
							</span>
							<span>{{ option.description }}</span>
						</div>
					</template>
					<template #selected-option="option">
						<div class="flex items-center">
							<span class="fa-squaress mr-1">
								<feather-icon
									:style="`color: ${option.color}; background: ${option.color}; border-radius: 3px;`"
									icon="SquareIcon"
									size="25"
								/>
							</span>
							<span>{{ option.description }}</span>
						</div>
					</template>
				</v-select>
				<v-select
					label="description"
					class="mx-1"
					:options="colorOptions"
					style="width: 220px"
					:reduce="(opt) => opt.description"
					v-model="selectedColor2"
					placeholder="Seleccionar color #2"
				>
					<template #option="option">
						<div class="flex items-center">
							<span class="fa-squaress mr-1">
								<feather-icon
									:style="`color: ${option.color}; background: ${option.color}; border-radius: 3px;`"
									icon="SquareIcon"
									size="25"
								/>
							</span>
							<span>{{ option.description }}</span>
						</div>
					</template>
					<template #selected-option="option">
						<div class="d-flex align-items-center justify-content-between">
							<span class="fa-squaress mr-1">
								<feather-icon
									:style="`color: ${option.color}; background: ${option.color}; border-radius: 3px;`"
									icon="SquareIcon"
									size="25"
								/>
							</span>
							<span>{{ option.description }}</span>
						</div>
					</template>
				</v-select>
				<button @click="exportPdf" class="btn btn-primary">
					<FeatherIcon icon="PrinterIcon" class="" />
					Exportar
				</button>
			</div>
		</template>

		<template v-slot:table>
			<b-table-simple sticky-header="calc(60vh - 100px)" class="position-relative">
				<b-thead>
					<b-tr class="text-center">
						<b-th class="p-1" v-for="(item, key) in headTable" :key="key" :style="item.style">
							<div
								class="d-flex align-items-center"
								:class="[item.class ? item.class : 'justify-content-center']"
							>
								<p class="mb-0">{{ item.text }}</p>
							</div>
						</b-th>
					</b-tr>
				</b-thead>

				<b-tbody>
					<template>
						<b-tr
							v-for="(postura, index) in posturas"
							:key="index"
							class="text-center"
							:variant="!!postura.qty_dead ? 'danger' : ''"
						>
							<b-td class="text-left">
								{{ postura.madrilla }}
							</b-td>
							<b-td class="text-left" :style="{ padding: 0, margin: 0 }">
								{{ postura.padrillo }}
							</b-td>

							<b-td>
								<CombChickenlegsCintillos
									v-if="postura.vivos + postura.chicks > 0"
									:postura="{
										id: postura.id,
										chickenleg_wich_leg: postura.cintillos.chickenleg_wich_leg,
										chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
										cintillo_color: postura.cintillos.hex_1,
										cintillo_description: postura.cintillos.description_1,
										second_color_color: postura.cintillos.hex_2,
										second_color_description: postura.cintillos.description_2,
										total_minus_infertile_eggs: 1,
									}"
									:chickenleg_wich_leg="1"
								/>
							</b-td>

							<b-td>
								<CombChickenlegsCintillos
									v-if="postura.vivos + postura.chicks > 0"
									:postura="{
										id: postura.id,
										chickenleg_wich_leg: postura.cintillos.chickenleg_wich_leg,
										chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
										cintillo_color: postura.cintillos.hex_1,
										cintillo_description: postura.cintillos.description_1,
										second_color_color: postura.cintillos.hex_2,
										second_color_description: postura.cintillos.description_2,
										total_minus_infertile_eggs: 1,
									}"
									:chickenleg_wich_leg="2"
								/>
							</b-td>
							<!-- CAMBIAR DE ESTADO A LOS POLLOS A ACTIVOS -->
							<b-td
								@click="
									postura.vivos > 0 && !disabledEncaste && changeStatusChicks(postura, false, index)
								"
								:class="[
									postura.vivos > 0 && !disabledEncaste
										? 'cursor-pointer text-primary font-weight-bolder'
										: '',
								]"
							>
								{{ postura.vivos }}
							</b-td>

							<b-td
								@click="
									;(postura.chicks && !disabledEncaste) > 0 &&
										changeStatusChicks(postura, true, index)
								"
								:class="[
									(postura.chicks && !disabledEncaste) > 0
										? 'cursor-pointer text-primary font-weight-bolder'
										: '',
								]"
							>
								{{ postura.chicks }}
								<u>
									{{
										posturas[index].chicks_list.length > 0
											? `(${posturas[index].chicks_list[0].plate})`
											: ""
									}}
								</u>
							</b-td>
						</b-tr>
					</template>
				</b-tbody>
				<b-tfoot>
					<b-tr>
						<b-th class="p-1"></b-th>
						<b-th class="p-1 text-center">TOTAL</b-th>
						<b-th class="p-1 text-center"></b-th>
						<b-th class="p-1 text-center"></b-th>
						<b-th class="p-1 text-center">{{ sumEggs(posturas, "vivos") }}</b-th>
						<b-th colspan="3" class="p-1 text-center">{{ sumEggs(posturas, "chicks") }}</b-th>
					</b-tr>
				</b-tfoot>
			</b-table-simple>
			<div>
				<b-form-textarea
					id="textarea"
					v-model="text"
					placeholder="Observaciones"
					rows="3"
					max-rows="6"
				></b-form-textarea>
			</div>
		</template>

		<template v-slot:btns>
			<div class="d-flex justify-content-end mt-1">
				<b-button variant="outline-primary mr-1 " @click="saveChicks(4)">Guardar</b-button>
				<b-button :disabled="status_stage == 3" variant="primary" @click="saveChicks(3)">Confirmar</b-button>
			</div>
		</template>

		<template v-slot:modals>
			<!-- VER FUTUROS POLLOS EN BORRADOR DE LA POSTURA EN PENDIENTES -->
			<b-modal
				hide-footer
				v-model="controllerCreateChicks.show"
				:title="controllerCreateChicks.title"
				size="lg"
				@hidden="closeCreateChicks"
			>
				<div class="mt-1">
					<div>
						<b-table-simple sticky-header class="position-relative">
							<b-thead>
								<b-tr class="text-center">
									<b-th class="font-small-2">MADRILLA</b-th>
									<b-th class="font-small-2">PADRILLO</b-th>
									<b-th class="font-small-2">PATA IZQ</b-th>
									<b-th class="font-small-2">PATA DER</b-th>
								</b-tr>
							</b-thead>

							<b-tbody>
								<b-tr v-for="(postura, index) in posturasCopy" :key="index" class="text-center">
									<b-td class="text-left">
										{{ postura.madrilla }}
									</b-td>
									<b-td class="text-left">
										{{ postura.padrillo }}
									</b-td>

									<b-td>
										<CombChickenlegsCintillos
											v-if="postura.vivos + postura.chicks > 0"
											:postura="{
												id: postura.id,
												chickenleg_wich_leg: postura.cintillos.chickenleg_wich_leg,
												chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
												cintillo_color: postura.cintillos.hex_1,
												cintillo_description: postura.cintillos.description_1,
												second_color_color: postura.cintillos.hex_2,
												second_color_description: postura.cintillos.description_2,
												total_minus_infertile_eggs: 1,
											}"
											:chickenleg_wich_leg="1"
										/>
									</b-td>

									<b-td>
										<CombChickenlegsCintillos
											v-if="postura.vivos + postura.chicks > 0"
											:postura="{
												id: postura.id,
												chickenleg_wich_leg: postura.cintillos.chickenleg_wich_leg,
												chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
												cintillo_color: postura.cintillos.hex_1,
												cintillo_description: postura.cintillos.description_1,
												second_color_color: postura.cintillos.hex_2,
												second_color_description: postura.cintillos.description_2,
												total_minus_infertile_eggs: 1,
											}"
											:chickenleg_wich_leg="2"
										/>
									</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</div>

					<hr class="mb-2" />

					<template v-if="chicks.length">
						<b-row class="px-1">
							<b-col cols="12" md="6" class="mb-1">
								<h6 class="mb-0">
									CINTILLOS EN LA ALA:
									<strong class="h4">{{ chicks.length }}</strong>
								</h6>
							</b-col>
							<b-col cols="12" md="6" class="mb-1">
								<!-- <div class="d-flex justify-content-end">
									<p class="mb-0 mr-1">{{ checkCorrelative ? "Con " : "Sin " }} correlativo</p>
									<b-form-checkbox :disabled="status_stage == 3" v-model="checkCorrelative" />
								</div> -->
							</b-col>
						</b-row>

						<validation-observer ref="form">
							<b-row>
								<b-col
									cols="12"
									md="6"
									class="mb-0"
									v-for="(chick, key) in chicks"
									:key="`${key}-chick`"
								>
									<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
										<div class="d-flex align-items-center">
											<b-input-group
												class="position-relative d-flex"
												size="lg"
												:prepend="`${key + 1}`"
											>
												<img
													v-if="[3, 4].includes(chick.status)"
													src="/lotes/mue.svg"
													class="mr-2 icon-correlative"
													alt="dead"
												/>
												<b-form-input
													:disabled="status_stage == 3 || [3, 4].includes(chick.status)"
													type="text"
													@input="(e) => writeCintillo(e, key)"
													:value="chick.plate"
													:placeholder="!checkCorrelative ? null : 'correlativo'"
													:state="errors[0] ? false : null || chick.showError"
													:class="{ 'pl-3': chick.season }"
												/>
												<!--  -->
												<p
													class="number-season mb-0 font-weight-bolder d-flex align-items-center"
													v-if="chick.season"
												>
													{{ chick.season }} -
												</p>
											</b-input-group>
											<div
												v-if="![3, 4].includes(chick.status) && status_stage !== 3 && chick.id"
												@click="openAddDeadCPCA(controllerCreateChicks.postura, key)"
											>
												<v-icon
													name="fa-skull-crossbones"
													scale="1.2"
													class="mr-2 cursor-pointer text-danger icon-width"
													style="margin-left: 8px"
												/>
											</div>
										</div>
										<small class="text-danger ml-3">{{ errors[0] }}</small>
									</ValidationProvider>
								</b-col>
							</b-row>
						</validation-observer>

						<div v-if="disabledCorrelative" class="d-flex align-items-center mt-2">
							<img src="/lotes/mue.svg" class="mr-50" alt="" />
							<p class="mb-0">Muertos</p>
						</div>

						<section v-if="chicksError.length" class="mt-2">
							<h4 class="text-danger">Repetidos</h4>

							<ul>
								<li
									v-for="(error, indexTwo) in chicksError"
									:key="`${indexTwo}-error`"
									class="font-weight-bolder"
								>
									<div class="d-flex">
										<p>
											{{
												`${error.code + " / "} ${error.alias ? error.alias + " - " : ""} ${
													error.plate
												}`
											}}
											:
										</p>
										<div class="ml-1 d-flex text-danger">
											<div
												class="ml-1"
												v-for="(chick, indexThree) in error.chicks"
												:key="`${indexThree}-chick`"
											>
												{{ chick + `${error.chicks[indexThree + 1] ? "," : "."}` }}
											</div>
										</div>
									</div>
								</li>
							</ul>
						</section>

						<div>
							<p class="font-small-1">
								* Puede matar cintillos en la pata antes de agregar el correlativo.
							</p>
						</div>

						<div class="d-flex justify-content-end mt-2">
							<b-button
								variant="primary"
								:disabled="status_stage == 3"
								v-if="!loading"
								@click="placeChicks()"
							>
								Guardar
							</b-button>
							<b-button variant="primary" disabled v-else>
								<b-spinner small />
								Cargando...
							</b-button>
						</div>
					</template>

					<template v-else>
						<h3 class="my-2 text-center">Sin Hijos</h3>
					</template>
				</div>
			</b-modal>

			<AddDeadCPCA v-if="controllerAddDeadCPCA" @hidden="closeAddDeadCPCA" :postura="postura_obj" />
			<CorralSelectorModal
				v-if="showCorralSelector"
				@hidden="showCorralSelector = false"
				@confirmSixthStage="confirmSixthStage"
			/>

			<b-modal v-model="modalRegisterCP.show" title="Matar cintillo pata" hide-footer>
				<DeadsCPLotes :postura="modalRegisterCP" @hidden="closeDeadCP" />
			</b-modal>

			<!-- <RegisterDeads
				v-if="modalRegisterCP.show"
				@hidden="closeDeadCP"
				:evolution-specimen="1"
				:lote_id="lote.id"
				:postura_id="controllerCreateChicks.postura_id"
			/> -->
		</template>
	</RevisionLotesSlot>
</template>

<script>
import lotesService from "@/services/lotes.service"
import CombChickenlegsCintillos from "../components/CombChickenlegsCintillos.vue"
import RevisionLotesSlot from "./RevisionLotesSlot.vue"
import AddDeadCPCA from "./AddDeadCPCA.vue"
import moment from "moment"
import RegisterDeads from "@/views/amg/deads/modals/RegisterDeads.vue"
import DeadsCPLotes from "./DeadsCPLotes"
import CorralSelectorModal from "./CorralSelectorModal.vue"
import VoiceRecorder from "@/views/amg/encaste/lotes/components/VoiceRecorder.vue"
export default {
	name: "RevisionChicks",
	components: {
		CombChickenlegsCintillos,
		AddDeadCPCA,
		RevisionLotesSlot,
		RegisterDeads,
		DeadsCPLotes,
		CorralSelectorModal,
		VoiceRecorder,
	},
	props: {
		lote: {
			type: Object,
		},
	},
	async mounted() {
		await this.init()
		this.getActiveColors()
	},
	computed: {
		sumEggs: () => (posturas, key) => {
			return posturas.reduce(function (previousValue, currentValue) {
				return previousValue + currentValue[key]
			}, 0)
		},
	},
	watch: {
		chicks(newValue, oldValue) {
			if (
				newValue.length == 0 &&
				this.controllerCreateChicks.show &&
				this.controllerCreateChicks.title == "PENDIENTES"
			) {
				setTimeout(() => {
					console.log("chicks", this.chicks, this.oldChicks)
					console.log("CK", newValue, oldValue)
					// if (newValue.length == 0) {
					// 	this.closeCreateChicks();
					// }
				}, 500)
			}
		},
		selectedColor1(newValue, oldValue) {
			this.init()
		},
		selectedColor2(newValue, oldValue) {
			this.init()
		},
	},
	data() {
		return {
			selectedColor1: null,
			selectedColor2: null,
			colorOptions: [],
			headTable: [
				{
					text: "MADRILLA",
					value: "madrilla",
					visible: true,
					class: "justify-content-center",
				},
				{
					text: "PADRILLO",
					value: "padrillo",
					visible: true,
					class: "justify-content-center",
				},
				{
					text: "PATA IZQ",
					value: "inc",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
					style: "width: 150px",
				},
				{
					text: "PATA DER",
					value: "inc",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
					style: "width: 150px",
				},
				{
					text: "PENDIENTES",
					value: "pen",
					image: "/lotes/lote-grid-3.svg",
					visible: true,
					style: "width: 100px",
				},
				{
					text: "COLOCADOS",
					value: "col",
					image: "/lotes/lote-grid-3.svg",
					visible: true,
					style: "width: 150px",
				},
			],
			posturas: [],
			posturasCopy: [],
			text: "",
			status_stage: 1,
			date_dob: new Date().toISOString().substr(0, 10),
			checkLote: false,
			controllerCreateChicks: {
				show: false,
				title: "Crear pollos",
				lote_id: null,
				number: null,
				stage: null,
				postura: {},
			},
			chicks: [],
			oldChicks: [],
			checkCorrelative: false,
			disabledCorrelative: false,
			keyEggLote: null,
			controllerAddDeadCPCA: false,
			indexAddDeadCPCA: -1,
			loading: false,
			chicksError: [],
			hasChangedChicks: false,
			modalRegisterCP: {
				show: false,
			},
			showCorralSelector: false,
			obtainedColors: [],
		}
	},
	methods: {
		async getActiveColors() {
			const { data } = await lotesService.getActiveColors()
			this.obtainedColors = this.obtainedColors.flatMap((item) => {
				if (item.color1 && item.color2) {
					return [item.color1, item.color2]
				}
				if (item.color1) {
					return [item.color1]
				}
				if (item.color2) {
					return [item.color2]
				}
				return []
			})
			this.colorOptions = data.filter((item) => this.obtainedColors.includes(item.color))
		},
		async init() {
			this.chicks = []
			this.oldChicks = []
			this.posturas = []
			this.isPreloading()
			const data = await lotesService.getStagesRevisionChicks({
				lote_id: this.lote.id,
				selectedColor1: this.selectedColor1,
				selectedColor2: this.selectedColor2,
			})
			this.posturas = data.chicks.map((item) => {
				let cintillos = {
					...item.cintillos,
					chickenleg_wich_leg:
						item.cintillos &&
						(item.cintillos.wich_leg == "IZQUIERDA" ? 1 : item.cintillos.wich_leg == "DERECHA" ? 2 : 3),
				}
				return {
					...item,
					cintillos: item.cintillos ? cintillos : null,
				}
			})
			this.obtainedColors = this.posturas.map((postura) => {
				if (postura.cintillos) {
					return {
						color1: postura.cintillos.hex_1,
						color2: postura.cintillos.hex_2,
					}
				}
				return {
					color1: null,
					color2: null,
				}
			})
			if (data.chicks.length > 0) {
				this.text = data.chicks[0].observation
				this.status_stage = data.chicks[0].status
			}
			this.isPreloading(false)
		},
		async handleVoiceRecorderResponse(data) {
			this.posturas = data.chicks.map((item) => {
				let cintillos = {
					...item.cintillos,
					chickenleg_wich_leg:
						item.cintillos &&
						(item.cintillos.wich_leg == "IZQUIERDA" ? 1 : item.cintillos.wich_leg == "DERECHA" ? 2 : 3),
				}
				return {
					...item,
					cintillos: item.cintillos ? cintillos : null,
				}
			})
			if (data.chicks.length > 0) {
				this.text = data.chicks[0].observation
				this.status_stage = data.chicks[0].status
			}
		},
		changeStatusChicks(postura, bool, key) {
			// console.log({ bool });
			this.chicksError = []
			this.checkCorrelative = false
			this.posturasCopy[0] = this.posturas[key]
			this.disabledCorrelative = bool

			this.controllerCreateChicks = {
				title: !bool ? "PENDIENTES" : "COLOCADOS",
				postura: postura,
				lote_id: this.lote.id,
				number: !bool ? postura.vivos : postura.chicks,
				show: true,
				stage: 3,
				egg_lote_id: postura.egg_lote_id,
				cintillo_pata_id: postura.cintillos.cp_id,
				key,
				postura_id: postura.postura_id,
			}

			for (let index = 0; index < this.controllerCreateChicks.number; index++) {
				this.chicks.push({
					plate: null,
				})
			}
			if (bool) {
				this.chicks = this.posturas[key].chicks_list.map((chick) => {
					if (!chick.plate.includes("-")) {
						return {
							...chick,
						}
					}
					return {
						...chick,
						plate: chick.plate.split("-")[1],
						season: chick.plate.split("-")[0],
					}
				})

				console.log("chicks", this.chicks)

				if (this.controllerCreateChicks.title === "PENDIENTES") {
					this.oldChicks = new Array(this.controllerCreateChicks.number).fill(null)
				} else {
					this.oldChicks = _.cloneDeep(this.chicks)
				}

				this.oldChicks.forEach((ele, index) => {
					if (this.chicks[index] !== null) {
						ele = this.chicks[index]
					}
				})
			}
		},
		async closeCreateChicks() {
			this.controllerCreateChicks = {
				show: false,
				title: "Crear pollos",
				lote_id: null,
				number: null,
				stage: null,
			}
			this.posturasCopy = []

			if (!_.isEqual(this.chicks, this.oldChicks)) {
				await this.init()
			}

			this.chicks = []
			this.oldChicks = []
		},
		generateCorrelative(key) {
			if (key !== null) {
				let num = this.chicks[key].plate
				this.chicks.forEach((ele, index) => {
					if (index > key && ![3, 4].includes(ele.status)) {
						num++
						this.chicks[index].plate = num
					}
				})
			}
		},
		async placeChicks() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				return
			}
			this.isPreloading(true)
			const uniquePlates = new Set()
			const hasDuplicatePlates = this.chicks.some((c) => {
				if (c.plate && uniquePlates.has(c.plate)) {
					return true
				}
				if (![3, 4].includes(c.status)) {
					uniquePlates.add(c.plate)
				}
				return false
			})
			if (hasDuplicatePlates) {
				this.showToast("warning", "top-right", "Cintillo en ala", "WarningIcon", "No debe haber repetidos")
				this.isPreloading(false)
				return
			}
			const resp = await lotesService.generateChicks({
				egg_lote_id: this.posturasCopy[0].egg_lote_id,
				postura_id: this.posturasCopy[0].postura_id,
				chicks: this.chicks
					.filter((c) => ![3, 4].includes(c.status))
					.map((ck) => {
						if (ck.id) {
							return {
								...ck,
								plate: ck.plate,
							}
						} else {
							return {
								...ck,
							}
						}
					}),
				to_update: this.disabledCorrelative,
			})

			if (resp.status) {
				this.closeCreateChicks()
				this.showToast("success", "top-right", "Cintillo en ala", "SuccessIcon", resp.message)
				await this.init()
				this.isPreloading(false)
			} else {
				this.showToast("warning", "top-right", "Cintillo en ala", "WarningIcon", resp.message)
				this.chicksError = resp.chicks_duplicate
				let takeChicksError = []
				for (const { chicks } of this.chicksError) {
					for (const plate of chicks) {
						takeChicksError.push(plate)
					}
				}
				this.chicks.forEach((ck) => {
					let item = takeChicksError.find((e) => e == ck.plate)
					if (item) {
						ck.showError = false
					}
				})

				this.isPreloading(false)
				await new Promise((resolve) => {
					setTimeout(() => {
						this.chicks.forEach((ck, index) => {
							let item = takeChicksError.find((e) => e == ck.plate)
							if (item) {
								ck.showError = null
								if (this.controllerCreateChicks.title === "PENDIENTES") {
									ck.plate = null
								} else {
									ck.plate = this.oldChicks[index].plate
								}
							}
						})

						resolve(true)
					}, 1500)
				})

				this.$refs.form.reset()
			}
			this.oldChicks = _.cloneDeep(this.chicks)
		},
		async saveChicks(stage) {
			if (stage == 4) {
				this.isPreloading()
				await lotesService.saveChicks({
					lote_id: this.lote.id,
					status_id: stage,
					observation: this.text,
				})
				this.isPreloading(false)
				this.showToast(
					"success",
					"top-right",
					"Cintillo en ala",
					"SuccessIcon",
					"Se guardo la etapa 6 de forma exitosa."
				)
				this.$emit("refresh")
				await this.init()
			} else {
				if (this.sumEggs(this.posturas, "vivos") > 0) {
					this.showToast(
						"warning",
						"top-right",
						"Confirmar cintillo en ala",
						"InfoIcon",
						"Para poder confirmar la etapa de asignar cintillos a todos los vivos, por favor."
					)
				} else {
					this.showCorralSelector = true
				}
			}
		},
		async confirmSixthStage(corral_id) {
			this.isPreloading()
			await lotesService.saveChicks({
				lote_id: this.lote.id,
				status_id: 3,
				observation: this.text,
				corral_id,
			})
			this.isPreloading(false)
			this.showToast(
				"success",
				"top-right",
				"Cintillo en ala",
				"SuccessIcon",
				"Se confirmo la etapa 6 de forma exitosa."
			)
			this.$emit("refresh")
			await this.init()
		},
		openAddDeadCPCA(postura, index) {
			console.log("sd", postura)
			let oldChick = this.oldChicks.find((o, pos) => pos === index)
			this.postura_obj = postura
			this.postura_obj.cond = this.controllerCreateChicks.title == "PENDIENTES" ? true : false
			// console.log('ADD', oldChick, this.oldChicks, index);
			if (oldChick) {
				postura.chicks_list[index].plate = oldChick.plate
				this.postura_obj.ca = postura.chicks_list[index]
				this.indexAddDeadCPCA = index
				this.controllerAddDeadCPCA = true
			} else {
				console.log("matar cp")
				this.modalRegisterCP.show = true
				this.modalRegisterCP.lote_id = this.lote.id
				this.modalRegisterCP.postura_id = postura.postura_id
				this.modalRegisterCP.egg_lote_id = postura.egg_lote_id
				this.modalRegisterCP.vivos = postura.vivos
				this.modalRegisterCP.cintillos = postura.cintillos
				this.modalRegisterCP.code = postura.code
			}
		},
		async closeAddDeadCPCA(hasChanged) {
			this.postura_obj = {}
			this.controllerAddDeadCPCA = false

			// this.changeStatusChicks()
			if (hasChanged) {
				if (this.controllerCreateChicks.title == "PENDIENTES") {
					this.chicks.splice(this.indexAddDeadCPCA, 1)
				}
				if (this.controllerCreateChicks.title == "COLOCADOS") {
					this.chicks[this.indexAddDeadCPCA].status = 3
				}
				this.$emit("refresh", !hasChanged)
				await this.init()
				this.changeStatusChicks(
					this.posturas[this.controllerCreateChicks.key],
					true,
					this.controllerCreateChicks.key
				)
			}
		},
		async closeDeadCP(bool) {
			// console.log("df", bool);
			this.modalRegisterCP.show = false
			if (bool) {
				// await this.init();

				// console.log("bool", bool, this.chicks.length, this.oldChicks);

				const chicksCurrency = this.chicks.length - 1

				if (chicksCurrency == 0) {
					this.controllerCreateChicks.show = false
					await this.init()
					// this.changeStatusChicks(
					// 	this.posturas[this.controllerCreateChicks.key],
					// 	false,
					// 	this.controllerCreateChicks.key
					// );
				} else {
					await this.init()
					this.changeStatusChicks(
						this.posturas[this.controllerCreateChicks.key],
						false,
						this.controllerCreateChicks.key
					)
				}

				// (this.chicks.length == 0) &&
			}
		},
		async exportPdf() {
			this.isPreloading()
			const resp = await lotesService.exportPdfLoteStageChicks({
				lote_id: this.lote.id,
			})
			this.isPreloading(false)

			const oMyBlob = new Blob([resp], {
				type: "application/pdf",
			}) // the blob
			let url = document.createElement("a")
			url.href = URL.createObjectURL(oMyBlob)
			url.download = `${this.lote.code}-ETAPA${this.lote.stage}-${moment().format("DD-MM-YYYY_HH:mm")}.pdf`
			url.click()
		},
		writeCintillo(plate, key) {
			this.chicks[key].plate = plate.replace(/[^0-9]/g, "")
			this.checkCorrelative && this.generateCorrelative(key)
		},
		escapeRegExp(string) {
			return string.replace(/[^0-9]/g, "")
		},
	},
}
</script>

<style lang="scss">
.container-create-chicks {
	margin-top: 1rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
}
.icon-correlative {
	position: absolute;
	bottom: 14px;
	right: 0px;
	z-index: 100;
}
.number-season {
	position: absolute;
	top: 50%;
	left: 50px;
	transform: translateY(-50%);
	z-index: 100;
	font-size: 14px;
}
</style>
